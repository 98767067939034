@font-face {
	font-family: 'Ubuntu';
	src: url(~fonts/Ubuntu--subset-latin-extended.woff2);
	font-weight: 400;
}

@font-face {
	font-family: 'Ubuntu';
	src: url(~fonts/Ubuntu-Bold--subset-latin-extended.woff2);
	font-weight: 700;
}

@font-face {
	font-family: 'PlayfairDisplay';
	src: url(~fonts/PlayfairDisplay-Black--subset-latin-extended.woff2);
	font-weight: 700;
}
