.root {
	display: flex;

	&[data-from='bot'] {
		justify-content: flex-start;

		.inner {
			justify-content: flex-start;
			// margin-right: var(--space-xl);
		}

		+ [data-from='bot'] {
			.botAvatar {
				visibility: hidden;
			}
		}
	}

	[data-from='human'] {
		justify-content: flex-start;

		.inner {
			justify-content: flex-start;
			// margin-right: var(--space-xl);
		}

		+ [data-from='human'] {
			.botAvatar {
				visibility: hidden;
			}
		}
	}

	&[data-from='user'] {
		justify-content: flex-end;

		.inner {
			justify-content: flex-end;
			margin-left: var(--space-xl);
		}
	}

	/* Component overrides */

	&[data-type='message'] .component {
		flex: 0 1 auto;
	}

	&[data-type='buttonGroup'] .component {
		max-width: var(--col-xs);
	}

	&[data-type='foodCard'] .component,
	&[data-type='map'] .component {
		max-width: var(--col-m);
	}

	&[data-type='imageCardCarousel'] {
		.botAvatar {
			display: none;
		}

		.component {
			width: 100%;
		}
	}
}

.inner {
	flex: 1;
	display: flex;
	align-items: flex-end;
	max-width: 100%;
}

.botAvatar {
	width: 32px;
	height: 32px;
	flex: 0 0 auto;
	margin-right: var(--space-s);
}

.component {
	flex: 1;
}
