@import 'style/typography-helpers';

.root {
	background-color: var(--color-sinbad--alpha65);
	display: flex;
	flex-flow: column;
	min-height: 100%;
	padding: var(--space-s);
}

.logotype {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: var(--space-s) 0;
	@media (min-width: $col-s) {
		margin: var(--space-l) 0;
	}

	svg {
		fill: var(--color-white);
		height: 25px;
	}
}

.header {
	h2 {
		margin: 0;
	}
}

.avatar {
	display: flex;
	align-self: center;
	justify-self: center;
	margin-bottom: auto;
	padding: var(--space-xl) var(--space-xl) 0 var(--space-xl);

	svg {
		width: 100%;
		fill: var(--color-white);
		max-height: 175px;

		@media (min-width: $col-s) {
			max-height: 250px;
		}
	}
}

.content {
	h2 {
		@include heading-2;
		margin: 0 20% 0 0;
	}

	h3 {
		@include heading-3;
		margin: 0 5% 0 0;
	}

	label {
		@include heading-2;
		display: inline-block;
		margin-bottom: var(--space-s);
	}
}

.footer {
	margin-top: var(--space-m);
	display: flex;
	justify-content: center;

	> * {
		flex: 1;
		max-width: 60%;
	}
}
