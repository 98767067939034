.root {
	display: flex;
	overflow-x: hidden;
	min-height: 100vh;
}

.stepContainer {
	flex: 1 0 auto;
	width: 100%;

	&[aria-hidden='true'] {
		pointer-events: none;
	}
}
