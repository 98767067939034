@import 'style/variables';

.root {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--color-grey--alpha50);
	transition: all 0.5s var(--ease-in-out-quart);

	&[aria-hidden='true'] {
		opacity: 0;
		pointer-events: none;

		.inner {
			transform: translateY(var(--space-xxxl));
		}
	}
}

.inner {
	display: flex;
	flex-flow: column;
	overflow: hidden;
	width: 100%;
	max-width: var(--col-s);
	max-height: 70vh;
	margin: var(--space-m);
	box-shadow: var(--shadow);
	border-radius: var(--radius);
	transition: all 0.5s var(--ease-in-out-quart);
}

.header {
	display: flex;
	justify-content: center;
	padding: var(--space-m);
	position: relative;

	[data-theme='sinbad'] & {
		background-color: var(--color-sinbad);
	}

	[data-theme='carnation'] & {
		background-color: var(--color-carnation);
	}

	[data-theme='apricot-peach'] & {
		background-color: var(--color-apricot-peach);
	}
}

.main {
	padding: var(--space-m);
	overflow-y: auto;
	background-color: var(--color-white);

	[data-theme='form'] & {
		padding: 0;
	}

	// Content
	> :first-child,
	> div > :first-child {
		margin-top: 0;
	}

	> :last-child,
	> div > :last-child {
		margin-bottom: 0;
	}
}

.icon {
	color: var(--color-white);
}

.avatar {
	width: 72px;
	height: 72px;
}

.close {
	position: absolute;
	top: var(--space-xs);
	left: var(--space-xs);
}

// Maybe move this to a global file?
html[data-lock-viewport='true'] {
	overflow-y: hidden;
}
