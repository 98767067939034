@import 'style/variables';
@import 'style/layout-helpers';
@import 'style/misc-helpers';

.root {
	a: 1;
}

.carousel {
	display: flex;
	padding: var(--space-s) var(--space-xl) var(--space-l);
	overflow-x: auto;
	position: relative;
	z-index: 1;
	scroll-snap-type: x mandatory;
	scroll-padding-left: var(--space-xl);
	scrollbar-width: none;
	-ms-overflow-style: none;

	&[data-has-selection='true'] {
		overflow-x: hidden;
	}

	> * {
		flex: 1 0 100%;
		display: flex;
		scroll-snap-align: start;

		> * {
			transition: all 0.5s var(--ease-in-out-quart);
		}

		&[data-active='false'] > * {
			transform: scale(0.9);
			opacity: 0.5;
		}
	}

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::after {
		content: '';
		display: block;
		flex: 1 0 auto;
		width: var(--space-m);
	}
}

.card {
	flex: 1 1 auto;
	overflow: hidden;
}
