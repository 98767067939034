.root {
	border-radius: var(--radius);
	overflow: hidden;
	box-shadow: var(--shadow);
}

.iframe {
	display: block;
	border: 0;
	width: 100%;
	height: 250px;
}
