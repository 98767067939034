@import 'style/typography-helpers';

.root {
	overflow-x: hidden;
	padding-bottom: var(--space-xxl);

	section > [data-from]:last-child {
		padding-bottom: var(--space-m);
	}
}

.welcome {
	@include heading-3;
	line-height: 1.4;
	margin-bottom: var(--space-xl);
	max-width: var(--col-xs);
}

.inputBar {
	position: fixed;
	z-index: 5;
	left: 0;
	right: 0;
	bottom: 0;
	margin-top: var(--space-xl);
	padding: var(--space-s) 0;
	background-color: var(--color-white);
}

.typingIndicator {
	background-color: var(--color-mercury);
	padding: var(--space-s) var(--space-s);
	border-radius: var(--radius);
	display: inline-flex;
	margin-bottom: var(--space-l);
}
