.root {
	align-items: center;
	display: flex;
}

.dot {
	animation: anim 1.5s infinite ease-in-out;
	border-radius: 999px;
	display: inline-block;
	width: 6px;
	height: 6px;
	margin-right: var(--space-xxxxs);
	background-color: var(--color-grey);

	&:nth-of-type(1) {
		animation-delay: 200ms;
	}

	&:nth-of-type(2) {
		animation-delay: 300ms;
	}

	&:nth-of-type(3) {
		animation-delay: 400ms;
	}
}

@keyframes anim {
	0% {
		transform: translateY(0px);
	}
	28% {
		transform: translateY(-5px);
	}
	44% {
		transform: translateY(0px);
	}
}
