.root {
	padding: var(--space-xxs) var(--space-s);
	border: 2px solid transparent;
	border-radius: var(--radius);
	overflow: hidden;

	&[data-from='bot'] {
		background: var(--color-mercury);
	}

	&[data-from='human'] {
		background: var(--color-mercury);
	}

	&[data-from='user'] {
		border-color: var(--color-mercury);
	}

	// Markdown container

	> :first-child,
	> div > :first-child {
		margin-top: 0;
	}

	> :last-child,
	> div > :last-child {
		margin-bottom: 0;
	}
}
