@import 'style/typography-helpers';

.root {
	background-color: var(--color-sinbad--alpha65);
	display: flex;
	flex-flow: column;
	min-height: 100%;
	padding: var(--space-s);
	position: absolute;
	top: 0;
	height: auto;
	width: 100%;
}

.content {
	display: block;
	align-items: center;
	justify-content: center;
	width: 100%;

	button {
		width: 100%;
		margin-top: var(--space-s);
	}
}

.bottom {
	margin-top: auto;
}
