@import 'style/variables';
@import 'style/typography-helpers';

.root {
	display: flex;
	flex-flow: column;
	justify-content: center;
	position: relative;

	&[data-is-checkable='true'] {
		> .field_and_label {
			display: flex;
			flex-flow: row;
			align-items: flex-start;
			justify-content: flex-start;
			padding-left: calc(2 * var(--space-m));
		}
	}

	input,
	select,
	textarea {
		appearance: none;
		width: 100%;
		margin: 0;
		padding: var(--space-xxs) var(--space-xxs);
		border: 1px solid var(--color-grey--alpha50);
		border-radius: var(--radius);
		font: inherit;
		background: var(--color-white) no-repeat center / 0 0;
		transition: 0.2s var(--ease-in-out-quart);
		transition-property: border, background, width;

		&:hover {
			border-color: var(--color-grey);
		}

		&:focus {
			outline: 0;
			border-color: var(--color-grey);
		}

		&[type='number'] {
			&:focus {
				+ .formatted_value {
					color: transparent;
				}
			}

			&:not(:focus) {
				color: transparent;
			}
		}
	}

	input[type='checkbox'],
	input[type='radio'] {
		flex: 0 0 auto;
		width: var(--space-m);
		height: var(--space-m);
		margin-left: calc(2 * var(--space-neg-m));
		margin-right: var(--space-m);
		border-color: var(--color-volcanic);

		&:checked {
			background-size: 100% 100%;
		}
	}

	input[type='checkbox'] {
		&:checked {
			background-color: var(--color-pelorous);
			background-image: url(~icons/check-white.svg);
			border-color: var(--color-pelorous);
		}
	}

	input[type='radio'] {
		border-radius: 999px;

		&:checked {
			background-image: radial-gradient(closest-side, var(--color-pelorous) 60%, transparent 65%);
		}
	}
}

.label {
	font-weight: 500;
	user-select: none;

	a {
		@include link;
	}
}

.description {
	@include small-copy;
	margin: var(--space-xxxs) 0 0;
}

.error {
	display: flex;
	align-items: center;
	margin: var(--space-xxs) 0 0 0;
	color: var(--color-poppy) !important;

	&_icon {
		margin-left: var(--space-xxs);
	}
}

.control_wrapper {
	position: relative;

	+ .label {
		font-weight: normal;
	}
}

.formatted_value {
	position: absolute;
	top: 0;
	left: 0;
	margin-top: -3px;
	padding: var(--space-xxxs) var(--space-xxs);
	line-height: 1;
	pointer-events: none;
	border: 1px solid transparent;
}

// Uh… doesn't really belong here but works kinda nicely :P
[class~='form-checkable-group'] {
	margin: var(--space-l) 0;

	.root {
		margin: var(--space-s) 0;
	}
}

[class~='form-radio-group'] {
	.root {
		margin: var(--space-s) 0;
	}
}
