@import 'style/typography-helpers.scss';

.root {
	box-shadow: var(--shadow);
	border-radius: var(--radius);
	padding: var(--space-xs);
	overflow: hidden;

	p {
		margin: 0;
	}
}

.linkWrapper {
	text-decoration: none;

	.body {
		text-decoration: underline;
	}
}

.banner {
	margin: var(--space-neg-xs);
	margin-bottom: var(--space-xs);
	background-color: var(--color-carnation);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: var(--space-s);

	.avatar {
		width: 72px;
		height: 72px;
	}
}

.title {
	@include heading-5;
}
