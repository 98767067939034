@import 'style/typography-helpers';

.root {
	border-radius: var(--radius);
	overflow: hidden;
	position: relative;
	padding: var(--space-xs);
	box-shadow: var(--shadow);
	display: flex;
	flex-flow: column;
	transition: all 0.3s var(--ease-in-out-quart);

	&[data-disabled='true'] {
		opacity: 0 !important;
	}
}

.image {
	width: calc(100% + (2 * var(--space-s)));
	height: 180px;
	min-height: 120px;
	max-height: 30vw;
	object-fit: cover;
	margin: var(--space-neg-s);
	margin-bottom: 0;
}

.name {
	@include heading-5;
	margin: var(--space-xxs) 0 0;
	padding-right: 40%;
	line-height: 1.2;
}

.tags {
	position: absolute;
	top: var(--space-xxs);
	right: var(--space-xxs);
	display: flex;
	justify-content: flex-end;
}

.buttons {
	margin: var(--space-xs) 0 0;

	[data-component='Button'] {
		line-height: 1.2;
	}
}
