@import 'style/misc-helpers';

@mixin heading-1 {
	font-family: var(--font-title-main);
	font-weight: 700;
	font-size: rem(48px);

	@media (min-width: $col-s) {
		font-size: rem(60px);
	}
}

@mixin heading-2 {
	font-family: var(--font-title-main);
	font-weight: 700;
	font-size: rem(38px);

	@media (min-width: $col-s) {
		font-size: rem(48px);
	}
}

@mixin heading-3 {
	font-family: var(--font-title-main);
	font-weight: 700;
	font-size: rem(36px);
}

@mixin heading-4 {
	font-family: var(--font-title-main);
	font-weight: 700;
	font-size: rem(26px);
}

@mixin heading-5 {
	font-family: var(--font-title-main);
	font-weight: 700;
	font-size: rem(22px);
}

@mixin heading-6 {
	font-family: var(--font-title-small);
	font-weight: 700;
	font-size: rem(26px);
}

@mixin body-copy {
	font-family: var(--font-body);
	font-size: var(--font-size-small);
	line-height: 1.7;

	@media (min-width: $col-m) {
		font-size: var(--font-size-base);
	}

	@media (min-width: $col-xl) {
		font-size: var(--font-size-large);
	}
}

@mixin small-copy {
	font-family: var(--font-body);
	font-size: var(--font-size-small);

	@media (min-width: $col-xl) {
		font-size: var(--font-size-base);
	}
}
