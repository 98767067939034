@import 'style/layout-helpers';

.root {
	&[data-type='default'] {
		display: grid;
		gap: var(--space-xxxs);
		grid-template-columns: repeat(2, 1fr);
	}

	&[data-type='entry'] {
		@include gap(xxxs);
	}

	&[data-type='imageCard'] {
		@include gap(xxxxs);
		display: flex;
		flex-flow: row wrap;
	}

	[data-component='Button'] {
		width: 100%;
	}
}
