.root {
	a: 1;
}

.inner {
	display: flex;
	justify-content: space-between;
	padding: var(--space-xxxs);
	border: 1px solid var(--color-grey--alpha50);
	border-radius: 9999px;
	font-size: 1.1rem;
}

.formItem {
	margin: 0 !important;
	flex: 1 1 auto;

	input {
		border: 0 !important;
		background-color: transparent !important;
	}
}

.nav {
	display: flex;
}

.icon {
	width: var(--space-m);
	height: var(--space-m);
}

.scannerOverlay {
	video,
	img {
		object-fit: cover !important;
		width: 100%;
		height: 50vh;
	}
}
