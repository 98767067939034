@import 'style/variables';
@import 'style/layout-helpers';

.root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: var(--space-s) 0;
	background-color: var(--color-white);
}

.botAvatar {
	svg {
		height: 32px;
	}
}

.logo {
	svg {
		height: 25px;
	}
}
