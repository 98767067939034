@import 'style/variables';
@import 'style/keyframes';
@import 'style/typography-helpers';

.root {
	appearance: none;
	background: transparent;
	white-space: nowrap;
	border: 0;
	padding: 0;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	font: inherit;
	line-height: 2;

	// Interaction state
	#{$interact} {
		&[data-animate='icon-left'] {
			[data-component='Icon'] {
				animation: wraparound-rl 0.5s var(--ease-in-out-quart);
			}
		}

		&[data-animate='icon-right'] {
			[data-component='Icon'] {
				animation: wraparound-lr 0.5s var(--ease-in-out-quart);
			}
		}
	}

	// Variants
	&[data-kind='plain'] {
		&[data-icon-only='true'] {
			.inner {
				padding: 0;
			}
		}
	}

	&[data-kind='black'] {
		.inner {
			border-color: var(--color-black);
			background-color: var(--color-black);
			color: var(--color-sinbad);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-sinbad);
					color: var(--color-black);
				}

				.effect {
					background-color: var(--color-sinbad);
				}

				[data-component='Icon'] {
					color: var(--color-black);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-sinbad);
		}
	}

	&[data-kind='royal-blue'] {
		.inner {
			border-color: var(--color-royal-blue);
			background-color: var(--color-royal-blue);
			color: var(--color-apricot-peach);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-apricot-peach);
					color: var(--color-royal-blue);
				}

				.effect {
					background-color: var(--color-apricot-peach);
				}

				[data-component='Icon'] {
					color: var(--color-royal-blue);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-apricot-peach);
		}
	}

	&[data-kind='hawkes-blue'] {
		.inner {
			border-color: var(--color-hawkes-blue);
			background-color: var(--color-hawkes-blue);
			color: var(--color-carnation);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-carnation);
					color: var(--color-hawkes-blue);
				}

				.effect {
					background-color: var(--color-carnation);
				}

				[data-component='Icon'] {
					color: var(--color-hawkes-blue);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-carnation);
		}
	}

	&[data-kind='sinbad'] {
		.inner {
			border-color: var(--color-sinbad);
			background-color: var(--color-sinbad);
			color: var(--color-white);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-white);
					color: var(--color-sinbad);
				}

				.effect {
					background-color: var(--color-white);
				}

				[data-component='Icon'] {
					color: var(--color-sinbad);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-white);
		}
	}

	&[data-kind='apricot-peach'] {
		.inner {
			border-color: var(--color-apricot-peach);
			background-color: var(--color-apricot-peach);
			color: var(--color-royal-blue);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-royal-blue);
					color: var(--color-apricot-peach);
				}

				.effect {
					background-color: var(--color-royal-blue);
				}

				[data-component='Icon'] {
					color: var(--color-apricot-peach);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-royal-blue);
		}
	}

	&[data-kind='carnation'] {
		.inner {
			border-color: var(--color-carnation);
			background-color: var(--color-carnation);
			color: var(--color-white);
		}

		&:not(:disabled) {
			#{$interact} {
				.inner {
					background-color: var(--color-apricot-peach);
					color: var(--color-carnation);
				}

				.effect {
					background-color: var(--color-apricot-peach);
				}

				[data-component='Icon'] {
					color: var(--color-carnation);
				}
			}
		}

		[data-component='Icon'] {
			color: var(--color-apricot-peach);
		}
	}

	&:not(:disabled) {
		#{$interact} {
			.inner {
				// transition: color 0.2s var(--ease-in-out-quart);
			}

			[data-component='Icon'] {
				// transition: color 0.2s var(--ease-in-out-quart);
			}
		}
	}

	&:disabled {
		cursor: default;

		.inner {
			background-color: var(--color-grey);
			border-color: var(--color-grey);
			color: var(--color-white);
		}
	}

	// Sizes
	&[data-size='large'] {
		@include heading-6;

		.inner {
			padding: var(--space-m) var(--space-l);
		}

		[data-component='Icon'] {
			svg {
				width: var(--space-xl);
				height: var(--space-xl);
			}
		}
	}

	// Icon only
	&[data-icon-only='true'] {
		.inner {
			padding: var(--space-xxs);
		}

		[data-component='Icon'] {
			margin: 0;
		}
	}

	// Icon buttons
	[data-component='Icon'] {
		svg {
			width: var(--space-m);
			height: var(--space-m);
		}
	}
}

.inner {
	border-radius: 999px;
	width: 100%;
	padding: var(--space-xxs) var(--space-s);
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: var(--color-black);
	position: relative;
	overflow: hidden;
	transition: all 0.3s var(--ease-in-out-quart);
	// border: 1px solid transparent;
}

.effect {
	position: absolute;
	width: 0;
	top: -1px;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 999px;
	// not using this for now
	display: none;
}

.body {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;

	// Accomodate icons
	> [data-component='Icon'] + *,
	> * + [data-component='Icon'] {
		margin-left: var(--space-xs);
	}
}

@keyframes effect {
	to {
		width: calc(100% + 2px);
	}
}
