@import 'style/variables';

:root {
	// Primary colours
	--color-royal-blue: #3a51dd;
	--color-hawkes-blue: #ccd0fb;
	--color-sinbad: #a1c7d6;
	--color-sinbad--alpha65: #{transparentize(#a1c7d6, 0.35)};
	--color-apricot-peach: #f9d2b8;
	--color-carnation: #f6694e;
	--color-black: #000000;
	--color-grey: #898787;
	--color-grey--alpha50: #{transparentize(#898787, 0.5)};
	--color-mercury: #e5e5e5;

	// Old colours, can probably be deleted
	--color-white: #ffffff;
	--color-white--alpha50: #{transparentize(#ffffff, 0.5)};
	--color-volcanic: #7c868d;
	--color-mallard: #006c68;
	--color-storm50: #e2e7ed;
	--color-storm: #c5cfdb;
	--color-storm--alpha50: #{transparentize(#c5cfdb, 0.5)};
	--color-storm--alpha: #{transparentize(#c5cfdb, 0.8)};
	--color-lake: #8dc7e9;
	--color-lake--alpha: #{transparentize(#8dc7e9, 0.8)};
	--color-spruce: #00839b;
	--color-moderna: #f6da40;
	--color-pelorous: #47a8be;
	--color-pelorous--alpha50: #{transparentize(#47a8be, 0.5)};
	--color-pelorous--alpha: #{transparentize(#47a8be, 0.8)};

	// Typography
	--text-color: #222222;
	--font-size-small: #{$font-size-small};
	--font-size-base: #{$font-size-base};
	--font-size-large: #{$font-size-large};
	--font-title-main: 'PlayfairDisplay', serif;
	--font-title-small: 'Ubuntu', Arial, sans-serif;
	--font-body: 'Ubuntu', Arial, sans-serif;

	// Spacing
	--space-xxxxs: 2px;
	--space-xxxs: 4px;
	--space-xxs: 8px;
	--space-xs: 12px;
	--space-s: 16px;
	--space-m: 24px;
	--space-l: 36px;
	--space-xl: 48px;
	--space-xxl: 72px;
	--space-xxxl: 96px;

	--space-neg-xxxxs: -2px;
	--space-neg-xxxs: -4px;
	--space-neg-xxs: -8px;
	--space-neg-xs: -12px;
	--space-neg-s: -16px;
	--space-neg-m: -24px;
	--space-neg-l: -36px;
	--space-neg-xl: -48px;
	--space-neg-xxl: -72px;
	--space-neg-xxxl: -96px;

	// Columns
	--col-xxs: #{$col-xxs};
	--col-xs: #{$col-xs};
	--col-s: #{$col-s};
	--col-m: #{$col-m};
	--col-l: #{$col-l};
	--col-xl: #{$col-xl};
	--col-max: #{$col-max};

	// Easings
	--ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
	--ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
	--ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);

	// Misc
	--shadow: 0 0.3px 1.1px rgba(0, 0, 0, 0.022), 0 0.7px 2.5px rgba(0, 0, 0, 0.032), 0 1.3px 4.8px rgba(0, 0, 0, 0.04), 0 2.2px 8.5px rgba(0, 0, 0, 0.048), 0 4.2px 15.9px rgba(0, 0, 0, 0.058), 0 10px 38px rgba(0, 0, 0, 0.08);
	--radius: 1.5em;
}
