@import 'style/variables';
@import 'style/layout-helpers';

.root {
	@include gap(l);
	display: flex;
	flex-flow: column;
	margin-top: var(--space-xl);

	@media (min-width: $col-m) {
		flex-flow: row wrap;
		align-items: center;

		> * {
			flex: 1 1 40%;
		}
	}
}

.main {
	h1 {
		margin-top: 0;
	}

	@media (min-width: $col-m) {
		order: 2;
	}
}

.wheels {
	position: relative;
	padding-bottom: 74%;
}

.wheel {
	position: absolute;
	animation: 25s linear infinite;

	&1 {
		width: 61%;
		bottom: 0;
		left: 0;
		animation: rotate-acw 32s linear infinite;
	}

	&2 {
		width: 35%;
		left: 52.5%;
		bottom: 52%;
		animation: rotate-cw 16s linear infinite;
	}

	&3 {
		width: 31.5%;
		right: 0;
		bottom: 15.5%;
		animation: rotate-acw 16s linear infinite;
	}

	svg {
		width: 100%;
	}
}

@keyframes rotate-cw {
	to {
		transform: rotate(360deg);
	}
}

@keyframes rotate-acw {
	to {
		transform: rotate(-360deg);
	}
}
