// Gutters
@mixin gap($size) {
	margin: var(--space-neg-#{$size});

	> * {
		margin: var(--space-#{$size});
	}
}

// Spacing
@mixin space($size) {
	margin-top: var(--space-#{$size});
	margin-bottom: var(--space-#{$size});
}
