@import 'style/misc-helpers';

// Default link style
.link {
	@include link;
}

// Visually hidden
.visually-hidden {
	@include visually-hidden;
}

// Default border/divider
.divider {
	border-bottom: 1px solid var(--color-storm);
}

// Sticky
.sticky {
	position: sticky;
	z-index: 5;
	top: 0;
}

@each $size in (xs s m l xl) {
	.sticky-#{$size} {
		position: sticky;
		z-index: 1;
		top: var(--space-#{$size});
	}
}

// Multicol text
.text-cols {
	columns: 20em;
	column-gap: var(--space-l);

	> :first-child {
		margin-top: 0;
	}

	> :last-child {
		margin-bottom: 0;
	}
}

// temp
[data-inview] {
	> * {
		transition: all 1s;
	}

	@for $i from 1 through 10 {
		> :nth-child(#{$i}) {
			transition-delay: 0.2s * $i;
		}
	}

	&[data-inview='false'] > * {
		opacity: 0;
		transform: translateY(var(--space-xl));
	}
}
